import React from 'react'
import ContactPage from 'templates/ContactPage'
import PropTypes from 'prop-types'

const Contact = ({ location }) => {
  return (
    <ContactPage
      location={location}
      pageContext={
        {
          title: 'Contact us',
          description: 'Send a message to the Caldeira Guesthouse. If you need any clarification or if you want to visit us do not hesitate to contact'
        }
      }
    />
  )
}

Contact.propTypes = {
  location: PropTypes.shape({}).isRequired,
}

export default Contact
