import React, { useState } from 'react'
import SEO from 'utils/seo'
import { graphql, useStaticQuery } from 'gatsby'
import {
  Container,
  Title,
  Text,
  Content,
  Reveal
} from 'components/atoms'
import { Contacts } from 'components/molecules'
import {
  Layout,
  ContactForm,
  Footer
} from 'components/organisms'
import styled, { withTheme } from 'styled-components'
import { useLang } from 'hooks'
import Img from 'gatsby-image'
import { FormattedMessage } from 'react-intl'
import { below } from 'utils/breakpoints'
import PropTypes from 'prop-types'
import { useMeasure } from 'react-use'

const translation = {
  pt: {
    message: 'Se necessita de algum esclarecimento ou se nos pretende visitar não hesite em contactar.',
    notice: 'Estamos a aproveitar o sossego da Caldeira, mas iremos responder.',
    alternative: 'Se não obtiver uma resposta estas são as alternativas:',
    error: 'Algo correu mal...',
    sorry: 'Pedimos desculpa, não foi possível enviar a mensagem.',
    errorAlt: 'Sugerimos as seguintes alternativas:',
    sent: 'Enviado para '
  },
  en: {
    message: 'If you need any clarification or if you want to visit us do not hesitate to contact',
    notice: 'We are enjoying the tranquility of Caldeira, but we will answer.',
    alternative: `If you don't hear from us here are some alternatives:`,
    error: `Something went wrong...`,
    sorry: 'We are sorry, we could not send the message.',
    errorAlt: 'We suggest the following alternatives:',
    sent: 'Sent to '
  }
}

const ContactPage = ({ pageContext, ...props }) => {
  const { langKey } = useLang(props.location)
  const data = translation[langKey]
  const queryData = useStaticQuery(query)

  const [hasSent, setHasSent] = useState(false)
  const [hasFailed, setHasFailed] = useState(false)
  const [values, setValues] = useState({})
  const [ref, { height }] = useMeasure()

  const handleSent = values => {
    setHasFailed(false)
    setHasSent(true)
    setValues(values)
  }

  const handleFailure = (_, values) => {
    setHasFailed(true)
    setHasSent(false)
    setValues(values)
  }

  return (
    <Layout inverseTheme={true} location={props.location}>
      <SEO
        title={pageContext.title}
        description={pageContext.description}
        lang={langKey}
        imageSrc={queryData.ogImage.childImageSharp.fluid.src}
      />
      <ContainerContact modifiers={['fixNav']} height={height}>
        <Hero>
          <Hero.Content>
            <Wrapper ref={ref}>
              <Reveal effect="fadeInBottom">
                <Title modifiers={['ml', 'weightMedium', 'inverse']} withSeperator>
                  <FormattedMessage id="contact" />
                </Title>
              </Reveal>
              <Content>
                <Text modifiers={['inverse']}>{data.message}</Text>
              </Content>
              <ContentForm>
                <FormWrapper>
                  {hasSent && (
                  <>
                    <Text modifiers={['sm', 'bold']}>
                      <FormattedMessage id="thank" />
                      {values.name ? ` ${ values.name }` : ''}!
                    </Text>
                    <Text>{data.notice}</Text>
                    {values.message && (
                      <>
                        <Message fixBottom>
                          <Text modifiers={['bold']}>
                            <FormattedMessage id="message" />
                          </Text>
                          <Text modifiers={['italic', 'alternative']}>{values.message}</Text>
                        </Message>
                        {values.email && <Text modifiers={['xxs', 'light']}>
                          {data.sent}
                          {values.email}
                        </Text>}
                      </>
                    )}
                    <Text>{data.alternative}</Text>
                    <Contacts />
                  </>
                  )}
                  {hasFailed && (
                  <>
                    <Text modifiers={['sm', 'bold']}>{data.error}</Text>
                    <Text>{data.sorry}</Text>
                    {values.message && (
                      <>
                        <Message>
                          <Text modifiers={['bold']}>
                            <FormattedMessage id="message" />
                          </Text>
                          <Text modifiers={['italic', 'alternative']}>{values.message}</Text>
                        </Message>
                      </>
                    )}
                    <Text>{data.errorAlt}</Text>
                    <Contacts />
                  </>
                  )}
                  {!hasSent && !hasFailed && (
                    <ContactForm
                      onSuccess={handleSent}
                      onFailure={handleFailure}
                      langKey={langKey}
                    />
                  )}
                </FormWrapper>
              </ContentForm>
            </Wrapper>
            <Hero.SpacingBottomFix />
          </Hero.Content>
          <Hero.Background>
            <Image data={queryData.placeholderImage} />
          </Hero.Background>
        </Hero>
      </ContainerContact>
      <Footer langKey={langKey} />
    </Layout>
  )
}

const ContainerContact = styled(Container)`
  height: ${ props => props.height ? `${ props.height }px` : 'auto' };
`

const ContentForm = styled(Content)`
  width: 100%;
  padding-top: 0;
  padding-bottom: ${ props => props.theme.spacing.xxl };
`

const Wrapper = styled.div`
  padding-top: ${ props => props.theme.spacing.xl };
  width: 100%;
`

const Message = styled.div`
  padding: ${ props => props.theme.spacing.xs };
  background-color: ${ props => props.theme.color.bgInverse };
  border-radius: ${ props => props.theme.value.borderRadius };
  margin-bottom: ${ props => props.fixBottom ? props.theme.spacing.xxs : props.theme.spacing.m };
  ${ Text } {
    color: ${ props => props.theme.color.baseInverse };
    font-size: ${ props => props.theme.size.xxs };
  }
`

const FormWrapper = styled.div`
  padding: ${ props => props.theme.spacing.l } ${ props => props.theme.spacing.xl };
  background-color: ${ props => props.theme.color.bg };
  background: #FFFFFF;
  box-shadow: 0 0 32px 0 rgba(0,0,0,0.10);
  border-radius: ${ props => props.theme.value.borderRadiusM };
  max-width: 700px;
  width: 100%;
  height: auto;
  text-align: left;
  margin-left: auto;
  margin-right: auto;


  ${ below.md`
      padding: ${ props => props.theme.spacing.m } ${ props => props.theme.spacing.m };
  ` };
    ${ below.sm`
      padding: ${ props => props.theme.spacing.s } ${ props => props.theme.spacing.s };
  ` };
`

const Hero = styled.div`
  height: 400px;
  min-height: 400px;
  width: 100%;
  padding-bottom: ${ props => props.theme.spacing.ms };
  position: relative;
`

Hero.Content = styled.div`
  position: absolute !important;
  z-index: ${ props => props.theme.zIndex.l };
  top: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
  ${ below.sm`
    h2 {
      font-size: ${ props => props.theme.size.sm };
    }
    padding: 0;
    padding-top: ${ props => props.theme.spacing.m };
  ` };
`

Hero.SpacingBottomFix = styled.div`
  width: 100%;
  height: ${ props => props.theme.spacing.ms };
`

Hero.Background = styled.div`
  width: 100%;
  height: 100%;
`

const Image = withTheme(({ theme, data }) => (
  <Img
    fluid={data.childImageSharp.fluid}
    style={{
      height: '100%',
      width: '100%',
      borderRadius: theme.value.borderRadius
    }}
  />

))

const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    ogImage: file(relativePath: { eq: "banner.png" }) {
      childImageSharp {
        fluid(maxWidth: 750) {
          src
        }
      }
    }
  }
`

ContactPage.propTypes = {
  pageContext: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  location: PropTypes.shape({}).isRequired
}

ContactPage.defaultProps = {
  pageContext: {
    title: 'Contact'
  },
}

export default ContactPage
